import React from "react";
import {
  detailingServices
} from "../utils/services";
import ServiceTabs from "./UI/ServiceTabs";

const Services = () => {
  return (
    <div id="services">
      <ServiceTabs service={detailingServices} />
    </div>
  );
};

export default Services;
